<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-card>
        <b-row>
          <b-col cols="6" class="my-top-row-col-6">
            <h4>Vardiya Kayıtları</h4>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                to="/kurumDetay"
                class="myButton"
            >
              <feather-icon icon="GridIcon" class="mr-50"/>
              Dashboard
            </b-button>
          </b-col>
        </b-row>
        <hr/>
        <b-row>
          <b-col cols="12">
            <table class="table table-sm table-striped table-hover">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">BAŞLANGIÇ</th>
                <th scope="col">BİTİŞ</th>
                <th scope="col">KONAKLAMA</th>
                <th scope="col">ONLİNE SATIŞ</th>
                <th scope="col">CİRO</th>
                <th scope="col">NAKİT GELİR</th>
                <th scope="col">KREDİ KARTI</th>
                <th scope="col">KREDİLİ SATIŞ</th>
                <th scope="col">TAHSİLATLAR</th>
                <th scope="col">GİDERLER</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(shift, index) in salaryListItems" :key="index">
                <td>
                  <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-primary"
                      no-caret
                  >
                    <template #button-content>
                      <feather-icon icon="MenuIcon"/>
                    </template>
                    <b-dropdown-item
                        :baslangic="baslangic(shift.baslangic)"
                        :to="{ path: '/vardiyaOzet/' + shift.vardiyaID }"
                    >
                      Vardiya Detayı
                    </b-dropdown-item>
                    <b-dropdown-item
                        :to="'/gelirKayitlari/' + shift.vardiyaID"
                    >Gelir Kayıtları
                    </b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="'/giderKayitlari/' + shift.vardiyaID"
                    >Gider Kayıtları
                    </b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="'/konaklamaKayitlari/' + shift.vardiyaID"
                    >Konaklama Kayıtları
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
                <td>
                  {{ baslangic(shift.baslangic) }}
                </td>
                <td>
                  {{ baslangic(shift.bitis) }}
                </td>
                <td>
                  {{ shift.konukSayi }}
                </td>
                <td
                    :style="{
                          color: shift.onlineCiro == 0 ? '#d15151' : 'black',
                        }"
                >
                  {{ shift.onlineCiro }}
                  <strong>₺</strong>
                </td>
                <td :style="{ color: shift.ciro == 0 ? '#d15151' : 'black' }">
                  {{ shift.ciro }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{ color: shift.nakit == 0 ? '#d15151' : 'black' }"
                >
                  {{ shift.nakit }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{
                          color: shift.krediKarti == 0 ? '#d15151' : 'black',
                        }"
                >
                  {{ shift.krediKarti }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{ color: shift.kredi == 0 ? '#d15151' : 'black' }"
                >
                  {{ shift.kredi }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{
                          color: shift.tahsilat == 0 ? '#d15151' : 'black',
                        }"
                >
                  {{ shift.tahsilat }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{ color: shift.gider == 0 ? '#d15151' : 'black' }"
                >
                  {{ shift.gider }}
                  <strong>₺</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <p class="mt-2 text-muted">Toplam Kayıt Sayısı {{ toplamKayit }}</p>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>
        </b-pagination>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      currentPage: 1,
      perPage: 1,
      rows: 200,
    }
  },
  watch: {
    currentPage(newVal) {
      this.$store.dispatch('vardiyaListesi', {
        sayfala: newVal || false,
      })
    },
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    this.$store
        .dispatch('vardiyaListesi', { sayfala: false })
        .then((res, position = 'bottom-right') => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch((err) => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    baslangic(data) {
      return data.slice(0, 16)
    },
    bitis(data) {
      return data.slice(0, 16)
    },
  },
  computed: {
    salaryListItems() {
      return _.orderBy(
          this.$store.getters.getVardiyaList.vardiyaListesi,
          'vardiyaID',
          'desc',
      )
    },
    toplamKayit() {
      return this.$store.getters.getVardiyaList.vardiyaListCount
    },
  },
  beforeDestroy() {
    this.$store.commit('vardiyaListAction', 'remove')
  },
}
</script>
<style>
.card-body {
  padding: 10px 25px;
}
</style>
<style scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 10px !important;
  background-color: #31526f;
  color: white;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: center;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}

tr {
  text-align: center;
}

.toplamKayit {
  width: 20% !important;
}

.pagination {
  transform: translate(83%, -40px) !important;
}

.my-top-row-col-6 {
  display: inherit;
  align-items: end;
}
</style>
